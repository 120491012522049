<template>
    <span v-if="value === 0" class="text-danger">Cancelada</span>
    <span v-if="value === 1" class="text-success">Programada</span>
    <span v-if="value === 2" class="text-warning">En Espera</span>
    <span v-if="value === 3" class="text-info">Finalizada</span>
    <span v-if="value === 4" class="text-info">No Realizada</span>
</template>

<script>
export default {
    name: 'CitaStatus',
    props: {
        value: [String, Number],
    },
};
</script>
